export default {
  locales: [
    {
      code: "ar",
      name: "عربي",
      iso: "ar",
      files: [
        "ar/common.json",
        "ar/user.json",
        "ar/informative.json",
        "ar/product.json",
        "ar/footer.json",
        "ar/checkout.json",
      ],
      dir: "rtl",
    },
    {
      code: "en",
      iso: "en",
      name: "English",
      files: [
        "en/common.json",
        "en/user.json",
        "en/informative.json",
        "en/product.json",
        "en/footer.json",
        "en/checkout.json",
      ],
      dir: "ltr",
    },
  ],
  // lazy: true,
  legacy: false,
  strategy: "no_prefix",
  langDir: "locale",
  defaultLocale: "en",
  detectBrowserLanguage: false,
};
